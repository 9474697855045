// plop_marker:layouts_import
import RemoteWorkHeader from "./remote-work-header/index.js";
import DevelopersCenterHeader from "./developers-center-header/index.js";
import WelcomeNewJoinersHeader from "./welcome-new-joiners-header/index.js";
import DynamicHeader from "./dynamic-header/index.js";
import BaseHeader from "./base-header/index.js";
import RegularHeader from "./regular-header/index.js";
export default {
    // plop_marker:layouts_add_layout
    RemoteWorkHeader,
    DevelopersCenterHeader,
    WelcomeNewJoinersHeader,
    DynamicHeader,
    BaseHeader,
    RegularHeader
};
