function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import { REGULAR_BUTTON_TYPE, SECONDARY_BUTTON_TYPE, SIGNUP_BUTTON_TYPE, YOUTUBE_POPUP_LINK_TYPE } from "segments/desktop/core-components/button/button-consts";
import RegularButtonFieldsConfig from "segments/desktop/core-components/button/regular-button/fields-config";
import SecondaryButtonFieldsConfig from "segments/desktop/core-components/button/secondary-button/fields-config";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import YouTubePopupButtonConfig from "segments/desktop/core-components/button/youtube-popup-button/fields-config";
import ThemeConfig from "segments/core-configs/theme-config";
import { ELEVATE_THEME_ID } from "segments/segments-service";
import { ELEVATE_BUTTON_COLLOR_NAME } from "segments/button-service";
const ExtendedRegularButtonFieldsConfig = _object_spread_props(_object_spread({}, RegularButtonFieldsConfig), {
    color: _object_spread_props(_object_spread({}, RegularButtonFieldsConfig.color), {
        possibleColors: [
            ...RegularButtonFieldsConfig.color.possibleColors,
            ELEVATE_BUTTON_COLLOR_NAME
        ]
    })
});
export default {
    bannerText: {
        type: FIELD_TYPES.TEXT,
        label: "Text",
        translatable: true
    },
    mobileBannerText: {
        type: FIELD_TYPES.TEXT,
        label: "Mobile Text",
        translatable: true
    },
    bannerCta: {
        type: FIELD_TYPES.TEXT,
        label: "Button Text",
        translatable: true
    },
    bannerHeight: {
        type: FIELD_TYPES.TEXT,
        label: "Banner Height"
    },
    theme: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Theme"
    },
    withCloseIcon: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show Close Button"
    },
    buttonConfig: {
        type: FIELD_TYPES.CONDITIONAL_NESTED,
        label: "Button",
        conditionalKeyName: "buttonType",
        options: [
            {
                text: REGULAR_BUTTON_TYPE,
                fieldsConfig: ExtendedRegularButtonFieldsConfig
            },
            {
                text: SECONDARY_BUTTON_TYPE,
                fieldsConfig: SecondaryButtonFieldsConfig
            },
            {
                text: SIGNUP_BUTTON_TYPE,
                fieldsConfig: SignupButtonFieldsConfig
            },
            {
                text: YOUTUBE_POPUP_LINK_TYPE,
                fieldsConfig: YouTubePopupButtonConfig
            }
        ]
    },
    stickImagesToText: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Stick images to text"
    },
    bannerIcon: {
        type: FIELD_TYPES.GALLERY,
        label: "Banner Icon"
    },
    bannerIconHeight: {
        type: FIELD_TYPES.TEXT,
        label: "Banner Icon Height"
    }
};
