import { FIELD_TYPES } from "constants/template-field-type";
import { buttonColors } from "segments/button-service";
export default {
    buttonText: {
        type: FIELD_TYPES.TEXT,
        label: "Text",
        translatable: true
    },
    leftSideIcon: {
        type: FIELD_TYPES.NESTED,
        label: "Left side icon",
        fields: {
            src: {
                type: FIELD_TYPES.GALLERY,
                label: "Icon"
            },
            size: {
                type: FIELD_TYPES.TEXT,
                label: "Size"
            }
        }
    },
    url: {
        type: FIELD_TYPES.TEXT,
        label: "Url"
    },
    platformUrl: {
        type: FIELD_TYPES.TEXT,
        label: "Platform url for existing accounts",
        labelDescription: "Optional. Use as relative path (e.g. /product_store/dev)"
    },
    color: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color",
        possibleColors: buttonColors
    },
    isOpenLinkInNewTab: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Open link in new tab"
    }
};
