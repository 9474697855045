import { FIELD_TYPES } from "constants/template-field-type";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Regular",
    fields: {
        showMenu: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Show Menu"
        }
    }
};
