import { FIELD_TYPES } from "constants/template-field-type";
import ThemeConfig from "segments/core-configs/theme-config";
import { COUNTRIES_DROPDOWN_OPTIONS } from "services/countries/countries-service";
import { DARK_MARBLE_COLOR_NAME } from "styles/color-consts";
import { CARD_BORDER_STYLE } from "segments/desktop/constants";
const COUNTRY_FILED_CONFIG = {
    country: {
        type: FIELD_TYPES.COMBO,
        label: "country",
        options: COUNTRIES_DROPDOWN_OPTIONS
    }
};
export const CARD_BORDER_STYLE_TO_LABEL = {
    [CARD_BORDER_STYLE.FULL]: "Full (default)",
    [CARD_BORDER_STYLE.TOP]: "Only Top",
    [CARD_BORDER_STYLE.BOTTOM]: "Only bottom",
    [CARD_BORDER_STYLE.NONE]: "None"
};
export const COUNTRY_DISPLAY_MODE = {
    INCLUDE: "Include",
    EXCLUDE: "Exclude"
};
export default {
    paddingTop: {
        type: FIELD_TYPES.TEXT,
        label: "Padding Top"
    },
    paddingBottom: {
        type: FIELD_TYPES.TEXT,
        label: "Padding Bottom"
    },
    mobilePaddingTop: {
        type: FIELD_TYPES.TEXT,
        label: "Mobile Padding Top"
    },
    visibilitySensorProps: {
        type: FIELD_TYPES.CONDITIONAL_NESTED,
        label: "Visibility sensor",
        conditionalKeyName: "useVisibilitySensor",
        options: [
            {
                text: "Yes",
                fieldsConfig: {
                    useInfinitely: {
                        type: FIELD_TYPES.BOOLEAN,
                        label: "Use on every visibility change"
                    },
                    visibilityOffset: {
                        type: FIELD_TYPES.TEXT,
                        label: "Visibility sensor offset (px/percentages only)"
                    },
                    easeInEffect: {
                        type: FIELD_TYPES.BOOLEAN,
                        label: "Easing effect"
                    }
                }
            }
        ]
    },
    wrapInCard: {
        type: FIELD_TYPES.CONDITIONAL_NESTED,
        label: "Wrap segment in card",
        conditionalKeyName: "active",
        options: [
            {
                text: "Yes",
                fieldsConfig: {
                    cardBackgroundColor: {
                        type: FIELD_TYPES.COLOR_PICKER,
                        label: "Card background color",
                        possibleColors: [
                            ...ThemeConfig.theme.possibleColors,
                            DARK_MARBLE_COLOR_NAME
                        ]
                    },
                    cardWidth: {
                        type: FIELD_TYPES.TEXT,
                        label: "Card max width"
                    },
                    cardBorderStyle: {
                        type: FIELD_TYPES.COMBO,
                        label: "Card border style",
                        options: [
                            {
                                value: CARD_BORDER_STYLE.FULL,
                                text: CARD_BORDER_STYLE_TO_LABEL[CARD_BORDER_STYLE.FULL]
                            },
                            {
                                value: CARD_BORDER_STYLE.TOP,
                                text: CARD_BORDER_STYLE_TO_LABEL[CARD_BORDER_STYLE.TOP]
                            },
                            {
                                value: CARD_BORDER_STYLE.BOTTOM,
                                text: CARD_BORDER_STYLE_TO_LABEL[CARD_BORDER_STYLE.BOTTOM]
                            },
                            {
                                value: CARD_BORDER_STYLE.NONE,
                                text: CARD_BORDER_STYLE_TO_LABEL[CARD_BORDER_STYLE.NONE]
                            }
                        ]
                    }
                }
            }
        ]
    },
    sticky: {
        type: FIELD_TYPES.CONDITIONAL_NESTED,
        label: "Sticky segment",
        conditionalKeyName: "isSticky",
        options: [
            {
                text: "Yes",
                fieldsConfig: {
                    stickyTopOffset: {
                        type: FIELD_TYPES.TEXT,
                        label: "Sticky top offset"
                    }
                }
            }
        ]
    },
    countrySpecificDisplaySettings: {
        type: FIELD_TYPES.CONDITIONAL_NESTED,
        label: "Country specific segment display",
        conditionalKeyName: "countryDisplayMode",
        options: [
            {
                text: COUNTRY_DISPLAY_MODE.INCLUDE,
                fieldsConfig: COUNTRY_FILED_CONFIG
            },
            {
                text: COUNTRY_DISPLAY_MODE.EXCLUDE,
                fieldsConfig: COUNTRY_FILED_CONFIG
            }
        ]
    },
    segmentMinHeight: {
        type: FIELD_TYPES.TEXT,
        label: "Segment min height"
    },
    mobilePaddingBottom: {
        type: FIELD_TYPES.TEXT,
        label: "Mobile Padding Bottom"
    },
    hideOnMobile: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Hide segment on mobile"
    },
    topTriangle: {
        type: FIELD_TYPES.NESTED,
        label: "Top triangle",
        fields: {
            isVisible: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Is visible"
            },
            color: {
                type: FIELD_TYPES.COLOR_PICKER,
                label: "Color",
                possibleColors: ThemeConfig.theme.possibleColors
            }
        }
    },
    bottomTriangle: {
        type: FIELD_TYPES.NESTED,
        label: "Bottom triangle",
        fields: {
            isVisible: {
                type: FIELD_TYPES.BOOLEAN,
                label: "Is visible"
            },
            color: {
                type: FIELD_TYPES.COLOR_PICKER,
                label: "Color",
                possibleColors: ThemeConfig.theme.possibleColors
            }
        }
    },
    segmentId: {
        type: FIELD_TYPES.TEXT,
        unAllowedChars: [
            "#"
        ],
        label: "Segment ID",
        labelDescription: "To link to this element insert the unique id (without # symbol). Consider adding 72px top padding (because of the header)"
    }
};
