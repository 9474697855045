import { FIELD_TYPES } from "constants/template-field-type";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Dynamic",
    fields: {
        logoImage: {
            type: FIELD_TYPES.NESTED,
            label: "Logo Image",
            fields: {
                src: {
                    type: FIELD_TYPES.GALLERY,
                    defaultImagesKeys: [],
                    label: "Select an Image (size: 167x42)"
                },
                href: {
                    type: FIELD_TYPES.TEXT,
                    label: "Href"
                }
            }
        },
        linkItems: {
            type: FIELD_TYPES.MULTIPLE_NESTED,
            label: "Links",
            maxLimit: 8,
            nestedConfig: {
                type: FIELD_TYPES.NESTED,
                fields: {
                    label: {
                        type: FIELD_TYPES.TEXT,
                        label: "Label"
                    },
                    url: {
                        type: FIELD_TYPES.TEXT,
                        label: "Url"
                    }
                }
            }
        }
    }
};
