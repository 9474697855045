// plop_marker:layouts_import
import BaseFooter from "./base-footer/index.js";
import RemoteWorkFooter from "./remote-work-footer/index.js";
import WelcomeNewJoinersFooter from "./welcome-new-joiners-footer/index.js";
import LongFooter from "./long-footer/index.js";
import SlimFooter from "./slim-footer/index.js";
export default {
    // plop_marker:layouts_add_layout
    BaseFooter,
    RemoteWorkFooter,
    WelcomeNewJoinersFooter,
    LongFooter,
    SlimFooter
};
