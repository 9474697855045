import { FIELD_TYPES } from "constants/template-field-type";
import { buttonColors } from "segments/button-service";
import { REGULAR_BUTTON_TYPE, SECONDARY_BUTTON_TYPE } from "../button-consts";
import regularButtonConfig from "segments/desktop/core-components/button/regular-button/fields-config";
const { buttonSize } = regularButtonConfig;
export default {
    buttonDisplayType: {
        type: FIELD_TYPES.COMBO,
        label: "Display type",
        options: [
            {
                value: SECONDARY_BUTTON_TYPE,
                text: "Secondary"
            },
            {
                value: REGULAR_BUTTON_TYPE,
                text: "Regular"
            }
        ]
    },
    buttonSize,
    buttonText: {
        type: FIELD_TYPES.TEXT,
        label: "Text",
        translatable: true
    },
    videoId: {
        type: FIELD_TYPES.TEXT,
        label: "Video ID"
    },
    color: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color",
        possibleColors: buttonColors
    },
    isWithArrow: {
        type: FIELD_TYPES.BOOLEAN,
        label: "With arrow"
    },
    isWithIcon: {
        type: FIELD_TYPES.BOOLEAN,
        label: "With icon"
    },
    isWithIconInsteadOfArrow: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show icon instead of arrow"
    },
    iconColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Icon color",
        possibleColors: buttonColors
    }
};
