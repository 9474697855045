import { FIELD_TYPES } from "constants/template-field-type";
import SignupButtonFieldsConfig from "segments/desktop/core-components/button/signup-button/fields-config";
import RegularButtonFieldsConfig from "segments/desktop/core-components/button/regular-button/fields-config";
import { SIGNUP_BUTTON_TYPE, REGULAR_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
export default {
    type: FIELD_TYPES.NESTED,
    label: "Base",
    fields: {
        showMenu: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Show Menu"
        },
        hideLogin: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Hide login button"
        },
        showButton: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Show Button"
        },
        hideLanguagePicker: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Hide Language Picker (when Show Menu is off)"
        },
        logoHref: {
            type: FIELD_TYPES.TEXT,
            label: "Override Logo Click URL"
        },
        logoSrc: {
            type: FIELD_TYPES.TEXT,
            label: "Override Logo Image"
        },
        buttonConfig: {
            type: FIELD_TYPES.CONDITIONAL_NESTED,
            label: "Button",
            conditionalKeyName: "buttonType",
            options: [
                {
                    text: SIGNUP_BUTTON_TYPE,
                    fieldsConfig: SignupButtonFieldsConfig
                },
                {
                    text: REGULAR_BUTTON_TYPE,
                    fieldsConfig: RegularButtonFieldsConfig
                }
            ]
        }
    }
};
