function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import { HEADER_POPUP_OPTIONS } from "constants/header-popup";
import SharedAdvancedFieldsConfig from "segments/desktop/shared-advanced-fields-config";
export default {
    type: FIELD_TYPES.NESTED,
    label: "",
    fields: _object_spread_props(_object_spread({}, SharedAdvancedFieldsConfig), {
        logoPopup: {
            type: FIELD_TYPES.COMBO,
            label: "Logo Popup",
            options: HEADER_POPUP_OPTIONS
        },
        mobileStickySignup: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Mobile sticky signup (In test don't touch)"
        },
        shouldHaveEnhancedProducts: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Show enhanced products in header (In test don't touch)"
        },
        mobileDarkHeader: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Mobile dark header (In test don't touch)"
        },
        simulateExistingAccountHeader: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Use existing account header (welcome back page)"
        },
        showSuccessStoriesInHeader: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Show success stories in header (In test don't touch)"
        },
        overrideClusterLogo: {
            type: FIELD_TYPES.NESTED,
            label: "Override cluster logo (don't touch)",
            fields: {
                url: {
                    type: FIELD_TYPES.TEXT,
                    label: "Override Logo Click URL"
                },
                src: {
                    type: FIELD_TYPES.TEXT,
                    label: "Override Logo Image"
                },
                alt: {
                    type: FIELD_TYPES.TEXT,
                    label: "Override Logo Alt Text"
                }
            }
        },
        shouldUseDynamicColor: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Use first fold dynamic color (In test don't touch)"
        },
        removeContactSalesButton: {
            type: FIELD_TYPES.BOOLEAN,
            label: "Remove contact sales button from header"
        }
    })
};
