function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) {
    try {
        var info = gen[key](arg);
        var value = info.value;
    } catch (error) {
        reject(error);
        return;
    }
    if (info.done) {
        resolve(value);
    } else {
        Promise.resolve(value).then(_next, _throw);
    }
}
function _async_to_generator(fn) {
    return function() {
        var self = this, args = arguments;
        return new Promise(function(resolve, reject) {
            var gen = fn.apply(self, args);
            function _next(value) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value);
            }
            function _throw(err) {
                asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err);
            }
            _next(undefined);
        });
    };
}
const Sentry = require("@sentry/nextjs");
const { trackBigBrainEventOnServer } = require("../bigbrain-tracking-service/bigbrain-tracking-service");
const { logException } = require("../../logging/logger");
const handleServerException = function() {
    var _ref = _async_to_generator(function*({ externalMessage, e, req, res, extraLogParams, bbEventParams = {} }) {
        // 1. log to Coralogix
        logException({
            externalMessage,
            e,
            req,
            res,
            extraLogParams
        });
        // 2. capture exception in Sentry
        Sentry.captureException(e);
        yield Sentry.flush(2000);
        // 3. track server exception on BigBrain
        yield trackBigBrainEventOnServer(bbEventParams.name || "server_exception", {
            kind: bbEventParams.kind,
            placement: req && req.url,
            info1: e && e.message,
            info2: req && req.params && req.params.id,
            directObject: req && req.hostname
        });
    });
    return function handleServerException(_) {
        return _ref.apply(this, arguments);
    };
}();
module.exports = {
    handleServerException
};
