function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import additionalTextConfig from "segments/desktop/core-components/button/additional-text-config";
import { signupButtonColorOptions } from "constants/color-picker-constants";
export default _object_spread_props(_object_spread({
    buttonColor: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color",
        possibleColors: signupButtonColorOptions
    },
    useCtaText: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Use override default CTA"
    },
    ctaText: {
        type: FIELD_TYPES.TEXT,
        label: "Override Default CTA",
        translatable: true
    },
    signupWithsolutionAppReferenceId: {
        type: FIELD_TYPES.TEXT,
        label: "Solution Id:",
        labelDescription: "Leave blank unless signup with solution needed - enter the solution's app reference id"
    }
}, additionalTextConfig), {
    buttonId: {
        type: FIELD_TYPES.TEXT,
        label: "Button ID",
        labelDescription: "board_kind in signup_main_cta_clicked BB event"
    },
    isMobileInputTransparent: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Use mobile transparent input",
        labelDescription: "To be used in case of white button"
    },
    signupProductId: {
        type: FIELD_TYPES.PRODUCTS,
        label: "Product",
        labelDescription: "Signup to monday product"
    },
    signupClusterId: {
        type: FIELD_TYPES.CLUSTERS,
        label: "Cluster",
        labelDescription: "Use only in generic pages if you want to assign a cluster"
    },
    showGoogleConnectOnMobile: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Mobile google connect",
        labelDescription: "Display google connect option in mobile pages"
    },
    existingAccount: {
        type: FIELD_TYPES.NESTED,
        label: "Existing account",
        fields: {
            platformUrlOverrideForExistingAccount: {
                type: FIELD_TYPES.TEXT,
                label: "Platform url override for existing account",
                labelDescription: "Internal platform Url to redirect existing account to"
            },
            existingAccountCtaText: {
                type: FIELD_TYPES.TEXT,
                label: "Override Default CTA for existing account",
                translatable: true
            }
        }
    },
    buttonAlign: {
        type: FIELD_TYPES.COMBO,
        label: "Button align",
        options: [
            {
                value: "left",
                text: "Left"
            },
            {
                value: "center",
                text: "Center"
            }
        ]
    },
    useExperimentalAnimation: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Use experimental animation"
    },
    forceInputHide: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Show Button Only"
    }
});
