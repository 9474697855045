import Colors from "styles/colors";
import { ALWAYS_ON_TOP, TOP_LAYER } from "constants/z-index";
import { BRAND_PRIMARY_YELLOW } from "styles/color-consts";
const HOVER_BORDER = `2px dashed #c6e2e8`; // hard-coded color so no one else will use it
const SELECTED_BORDER = `2px solid ${Colors["bold-blue"]}`;
export const componentStyles = new String(`.preview-base-segment-component{height:100%;width:100%;position:relative;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;background:white;cursor:pointer;&.preview-base-template-draggable-helper-element{overflow:hidden;-webkit-box-shadow:0px 2px 16px 0 rgba(0,0,0,.2);-moz-box-shadow:0px 2px 16px 0 rgba(0,0,0,.2);box-shadow:0px 2px 16px 0 rgba(0,0,0,.2);cursor:move;.preview-overlay,.base-layout-segment-component{zoom:50%}}&:hover,&.is-selected,&.preview-base-template-draggable-helper-element{.preview-overlay{border:${HOVER_BORDER};opacity:1}}&.is-selected{.preview-overlay{border:${SELECTED_BORDER}}}.icon-wrapper{position:relative;margin:6px;background:white;-webkit-box-shadow:0 4px 8px 0 rgba(0,0,0,.16);-moz-box-shadow:0 4px 8px 0 rgba(0,0,0,.16);box-shadow:0 4px 8px 0 rgba(0,0,0,.16);-webkit-border-radius:8px;-moz-border-radius:8px;border-radius:8px;height:60px;width:60px;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-webkit-align-items:center;-moz-box-align:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-moz-box-pack:center;-ms-flex-pack:center;justify-content:center;-webkit-transition:-webkit-transform 150ms ease;-moz-transition:-moz-transform 150ms ease;-o-transition:-o-transform 150ms ease;transition:-webkit-transform 150ms ease;transition:-moz-transform 150ms ease;transition:-o-transform 150ms ease;transition:transform 150ms ease;pointer-events:all;z-index:${TOP_LAYER};svg {
        height: 36px;
        width: 36px;
      }

      &.location-icon-wrapper {
        cursor: auto;
        background: ${Colors[BRAND_PRIMARY_YELLOW]};
        z-index: ${TOP_LAYER};
        position: absolute;
        right: 32px;
        top: 32px;
      }
    &:hover{-webkit-transform:scale(1.1);-moz-transform:scale(1.1);-ms-transform:scale(1.1);-o-transform:scale(1.1);transform:scale(1.1)}}.preview-overlay{position:absolute;display:-webkit-box;display:-webkit-flex;display:-moz-box;display:-ms-flexbox;display:flex;padding:32px 0 0 32px;top:0;right:0;bottom:0;left:0;z-index:${ALWAYS_ON_TOP};-webkit-transition:opacity 200ms ease;-moz-transition:opacity 200ms ease;-o-transition:opacity 200ms ease;transition:opacity 200ms ease;opacity:0;border:2px solid transparent;pointer-events:none;.icon-wrapper{&.drag-icon-wrapper{cursor:move}&.edit-icon-wrapper{cursor:pointer}&.remove-icon-wrapper{cursor:pointer}&.copy-icon-wrapper{cursor:pointer}&.paste-icon-wrapper{cursor:pointer}&.disabled{background:#d6d4d4;cursor:auto;&:hover{-webkit-transform:scale(1);-moz-transform:scale(1);-ms-transform:scale(1);-o-transform:scale(1);transform:scale(1)}}}}}.icon-wrapper-tooltip{font-size:1.238rem;top:45px!important;z-index:${TOP_LAYER}}.has-copied{position:absolute;bottom:-20px;font-size:1rem}`);
componentStyles.__hash = "54eca55c0fb43286";
