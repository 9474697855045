function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
import { FIELD_TYPES } from "constants/template-field-type";
import { buttonColors } from "segments/button-service";
import { SM, MD, LG } from "components/core/new-button/button-component";
import additionalTextConfig from "segments/desktop/core-components/button/additional-text-config";
export default _object_spread({
    buttonSize: {
        type: FIELD_TYPES.COMBO,
        label: "Size",
        options: [
            {
                value: SM,
                text: "Small"
            },
            {
                value: MD,
                text: "Medium"
            },
            {
                value: LG,
                text: "Large"
            }
        ]
    },
    buttonText: {
        type: FIELD_TYPES.TEXT,
        label: "Text",
        translatable: true
    },
    url: {
        type: FIELD_TYPES.TEXT,
        label: "Url"
    },
    isOpenLinkInNewTab: {
        type: FIELD_TYPES.BOOLEAN,
        label: "Open link in new tab"
    },
    color: {
        type: FIELD_TYPES.COLOR_PICKER,
        label: "Color",
        possibleColors: buttonColors
    },
    outline: {
        type: FIELD_TYPES.BOOLEAN,
        label: "White button with color outline"
    }
}, additionalTextConfig);
