import { FIELD_TYPES } from "constants/template-field-type";
import { BELOW, SIDE } from "constants/button_additional_text_position";
export default {
    additionalText: {
        type: FIELD_TYPES.TEXT,
        label: "Additional text (monday markup supported)",
        translatable: true
    },
    additionalTextMaxWidth: {
        type: FIELD_TYPES.TEXT,
        label: "Additional text max width"
    },
    additionalTextPosition: {
        type: FIELD_TYPES.COMBO,
        options: [
            {
                value: SIDE,
                text: "Side"
            },
            {
                value: BELOW,
                text: "Below"
            }
        ],
        label: "Position of additional text",
        labelDescription: "mobile version will always be shown below"
    }
};
